import { createContext, useContext, useMemo, useState } from 'react';
import { ContextProviderProps } from './context-provider-types';

export type LabelTypeType =
    | 'valid'
    | 'invalid'
    | 'semivalid'
    | 'passed'
    | 'failed'
    | '';

type PageHeaderContextValue = {
    pageName: string;
    setPageName: (newPageName: string) => void;
    labelType: LabelTypeType;
    setLabelType: (newLabelType: LabelTypeType) => void;
};

export const PageHeaderContext = createContext<PageHeaderContextValue | null>(
    null,
);

export const usePageHeaderContext = (): PageHeaderContextValue => {
    const courseContext = useContext(PageHeaderContext);

    if (courseContext === null) {
        throw new Error(
            "useCourseContext can't be used outside a PageHeaderContextProvider",
        );
    }

    return courseContext;
};

export default function PageHeaderContextProvider({
    children,
}: ContextProviderProps) {
    const [pageName, setPageName] = useState('');
    const [labelType, setLabelType] = useState<LabelTypeType>('');
    const value = useMemo(() => {
        return { pageName, setPageName, labelType, setLabelType };
    }, [pageName, setPageName, labelType, setLabelType]);

    return (
        <PageHeaderContext.Provider value={value}>
            {children}
        </PageHeaderContext.Provider>
    );
}
