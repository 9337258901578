import { createContext } from 'react';

import { useRouter } from 'next/router';
import { Role } from '@/gql/graphql';

import getRoute from '@/common/utils/routes';
import { checkIfOnLoginOrResetPasswordRouteOrError } from '@/tools/auth/utils';
import { ContextProviderProps } from './context-provider-types';
import { useCurrentUserContext } from './CurrentUserContext';

export const AuthContext = createContext<null>(null);

export default function AuthProvider({ children }: ContextProviderProps) {
    const user = useCurrentUserContext();
    const router = useRouter();

    if (
        !user.role &&
        !checkIfOnLoginOrResetPasswordRouteOrError(router.pathname)
    ) {
        return null;
    }

    if (user.role === Role.Client && !router.pathname.startsWith('/client/')) {
        router.replace(getRoute('clientDashboard'));
        return null;
    }

    if (user.role === Role.User && !router.pathname.startsWith('/elearning/')) {
        router.replace(getRoute('elearningCurrentTrainings'));
        return null;
    }

    return <AuthContext.Provider value={null}>{children}</AuthContext.Provider>;
}
