import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useUserStore } from '@/tools/user/store/userStore';
import { ContextProviderProps } from './context-provider-types';
import { useCurrentUserContext } from './CurrentUserContext';

type CurrentBranchContextValue = {
    isClient: boolean;
};

export const CurrentBranchContext =
    createContext<CurrentBranchContextValue | null>(null);

export const useCurrentBranchContext = (): CurrentBranchContextValue => {
    const courseContext = useContext(CurrentBranchContext);

    if (courseContext === null) {
        throw new Error(
            "useCurrentBranchContext can't be used outside a CurrentBranchContext",
        );
    }

    return courseContext;
};

export default function CurrentBranchProvider({
    children,
}: ContextProviderProps) {
    const { branchId, setBranchId } = useUserStore();
    const router = useRouter();

    const isClient = useMemo(
        () => router.asPath.includes('/client/'),
        [router.asPath],
    );

    const nonLoginResetPasswordPage = useMemo(
        () =>
            !router.asPath.includes('/login') &&
            !router.asPath.includes('/resetPassword'),
        [router.asPath],
    );

    const branchIdStoredNotInQuery = useMemo(
        () => !router.query.branchId && branchId,
        [branchId, router.query.branchId],
    );

    useEffect(() => {
        // get branchId from query
        if (isClient) {
            const newBranchId = Array.isArray(router.query.branchId)
                ? router.query.branchId[0]
                : router.query.branchId;
            if (newBranchId) {
                setBranchId(newBranchId);
            }
        }
    }, [isClient, router.query.branchId, setBranchId]);

    useEffect(() => {
        if (branchIdStoredNotInQuery && isClient && nonLoginResetPasswordPage) {
            // set branchId to query
            router.replace({
                ...router,
                query: {
                    ...router.query,
                    branchId,
                },
            });
        }
    }, [
        branchId,
        branchIdStoredNotInQuery,
        isClient,
        nonLoginResetPasswordPage,
        router,
    ]);

    const value = useMemo(() => {
        return {
            isClient,
        };
    }, [isClient]);

    return (
        <CurrentBranchContext.Provider value={value}>
            {children}
        </CurrentBranchContext.Provider>
    );
}
