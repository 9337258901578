// import { useMemo } from 'react';
import Cookies from 'js-cookie';
import { useCallback } from 'react';

export type AuthStore = {
    accessToken: string | null;
    setAccessToken: (accessToken: string | null) => void;
    refreshToken: string | null;
    setRefreshToken: (refreshToken: string | null) => void;
};

export const ACCESS_TOKEN_COOKIE_NAME = 'accessToken';
export const REFRESH_TOKEN_COOKIE_NAME = 'refreshToken';

export function useAccessToken(): [string | undefined, () => void] {
    const removeAccessToken = useCallback(() => {
        Cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
    }, []);

    return [Cookies.get(ACCESS_TOKEN_COOKIE_NAME), removeAccessToken];
}

export function getAccessToken(): string | undefined {
    return Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
}
