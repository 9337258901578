import type { ReactElement, ReactNode } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ApolloProvider } from '@apollo/client';
import { Roboto } from 'next/font/google';
import { csCZ } from '@mui/x-date-pickers/locales';
import { appWithTranslation } from 'next-i18next';
import { SnackbarProvider } from 'notistack';
import { createTheme, ThemeProvider } from '@mui/material';

import type { NextPage } from 'next';
import type { AppProps } from 'next/app';

import '@/../public/styles.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import sk from 'date-fns/locale/sk';
import CurrentUserProvider from '@/context/CurrentUserContext';
import PageHeaderContextProvider from '@/context/PageHeaderContext';
// import { StoreProvider } from '@/context/StoreProvider';
import { useApollo } from '@/lib/apolloClient';
import ToastCloseButton from '@/components/ToastCloseButton/ToastCloseButton';
import CurrentBranchProvider from '@/context/CurrentBranchContext';
import AuthProvider from '@/context/AuthContext';

const roboto = Roboto({
    weight: '400',
    preload: false,
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#4039C6',
        },
        navigationBg: {
            main: '#242248',
        },
        navigationItemBg: {
            main: '#FFFFFF1A',
        },
        primaryBlue: {
            light: '#4039C60A',
            main: '#4039C6',
            dark: '#332E9E',
        },
        lightBlue: {
            main: '#EFF0FB',
            dark: '#c9cdf6',
        },
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        tableCell: {
            fontWeight: 500,
            fontSize: '14px',
            letterSpacing: '0.02em',
        },
        secondaryLabel: {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '143%',
            letterSpacing: '0.02em',
            color: '#63627C',
        },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    tableCell: 'span',
                    secondaryLabel: 'span',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.color === 'primary' && {
                        ...(ownerState.variant === 'contained' && {
                            backgroundColor: '#4039C6',
                            '&:hover': {
                                backgroundColor: '#332E9E',
                            },
                        }),
                        ...(ownerState.variant === 'outlined' && {
                            borderColor: '#4039C6',
                            color: '#4039C6',
                            '&:hover': {
                                backgroundColor: '#4039C60A',
                                borderColor: '#4039C6',
                            },
                        }),
                        ...(ownerState.variant === 'text' && {
                            color: '#4039C6',
                            '&:hover': {
                                backgroundColor: '#4039C60A',
                            },
                        }),
                    }),
                }),
            },
        },
        MuiChip: {
            styleOverrides: {
                label: {
                    lineHeight: '32px',
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#4039C6',
                    '&:hover': {
                        backgroundColor: '#4039C60A',
                    },
                },
                disabled: {
                    color: 'gray!important',
                },
            },
        },
        MuiTouchRipple: {
            styleOverrides: {
                child: {
                    color: '#4039C6!important',
                },
                childPulsate: {
                    color: '#4039C6!important',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    borderColor: '#4039C6',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    backgroundColor: '#EFF0FB',
                    '&.Mui-selected': {
                        backgroundColor: '#4039C6',
                        color: 'white',
                        borderColor: '#4039C6',
                    },
                    '&:first-of-type': {
                        borderTopLeftRadius: 4,
                        borderBottomLeftRadius: 4,
                    },
                    '&:last-child': {
                        borderTopRightRadius: 4,
                        borderBottomRightRadius: 4,
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    display: 'none',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.Mui-focusVisible': {
                        backgroundColor: '#4039C6!important',
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: '#4039c6',
                    fontSize: '16px',
                    fontWeight: 500,
                    letterSpacing: '0.02em',
                    textDecorationColor: '#4039c6',
                },
            },
        },
    },
});

const queryClient = new QueryClient();

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
    // Use the layout defined at the page level, if available
    const getLayout = Component.getLayout || ((page) => page);

    const apolloClient = useApollo(pageProps);

    return (
        <main className={roboto.className}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider
                    hideIconVariant
                    autoHideDuration={null}
                    action={(snackbarKey) => (
                        <ToastCloseButton snackbarKey={snackbarKey} />
                    )}
                >
                    <QueryClientProvider client={queryClient}>
                        <ApolloProvider client={apolloClient}>
                            {/* <StoreProvider store={store}> */}
                            <CurrentUserProvider>
                                <AuthProvider>
                                    <CurrentBranchProvider>
                                        <LocalizationProvider
                                            localeText={
                                                csCZ.components
                                                    .MuiLocalizationProvider
                                                    .defaultProps.localeText
                                            }
                                            dateAdapter={AdapterDateFns}
                                            adapterLocale={sk}
                                        >
                                            <PageHeaderContextProvider>
                                                {getLayout(
                                                    <Component
                                                        {...pageProps}
                                                    />,
                                                )}
                                            </PageHeaderContextProvider>
                                        </LocalizationProvider>
                                    </CurrentBranchProvider>
                                </AuthProvider>
                            </CurrentUserProvider>
                            {/* </StoreProvider> */}
                        </ApolloProvider>
                    </QueryClientProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </main>
    );
    // );
}

export default appWithTranslation(App);
