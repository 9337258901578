import { UrlObject } from 'url';

const routes = {
    // Client routes
    clientDashboard: '/client/dashboard',
    clientAudits: '/client/audits',
    clientAuditsDetail: '/client/audits/[id]',
    clientDevices: '/client/devices',
    clientDevicesDetail: '/client/devices/[id]',
    clientDocumentations: '/client/documentations',
    clientDocumentationsDetail: '/client/documentations/[id]',
    clientLogin: '/client/login',
    clientNews: '/client/news',
    clientNewsDetail: '/client/news/[id]',
    clientResetPassword: '/client/resetPassword',
    clientTrainings: '/client/trainings',
    clientTrainingsDetail: '/client/trainings/[id]',
    clientTrainingsDetailUser: '/client/trainings/user/[id]',
    clientProfile: '/client/user',
    // Admin routes
    adminLogin: '/admin/login',
    adminNews: '/admin/news',
    adminNewsCreate: '/admin/news/new',
    adminNewsDetail: '/admin/news/[id]',
    adminNewsUpdate: '/admin/news/[id]/update',
    adminClientZone: '/admin/client_zone',
    adminClientZoneDetail: '/admin/client_zone/[id]',
    adminTrainings: '/admin/trainings',
    adminTrainingsNew: '/admin/trainings/new',
    adminTrainingsDetail: '/admin/trainings/[id]',
    adminElearning: '/admin/elearning',
    adminElearningDetail: '/admin/elearning/[id]',
    adminElearningDetailRecordBook:
        '/admin/elearning/[id]/recordBook/[instanceId]',
    adminSynchronize: '/admin/bts',
    // Elearning routes
    elearningCourse: '/elearning/course/[id]',
    elearningCurrentTrainings: '/elearning/current_trainings',
    elearningCompletedTrainings: '/elearning/completed_trainings',
    elearningLogin: '/elearning/login',
    elearningResetPassword: '/elearning/resetPassword',
    elearningProfile: '/elearning/user',
    // other routes
    termsOfUse: '/terms',
    privacyPolicy: '/privacy',
    securityPolicy: '/security',
};

export type RouteNames = keyof typeof routes;

export default function getRoute(
    routeName: RouteNames,
    params: Record<string, string> = {},
    query: Record<string, string> = {},
): UrlObject & { pathname: string } {
    let routeWithParams = routes[routeName];
    Object.entries(params).forEach(([key, value]) => {
        routeWithParams = routeWithParams.replace(
            new RegExp(`\\[${key}]`, 'g'),
            value,
        );
    });

    return {
        pathname: routeWithParams,
        query,
    };
}
