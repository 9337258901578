import { useMemo } from 'react';
import {
    ApolloClient,
    ApolloLink,
    from,
    InMemoryCache,
    NormalizedCacheObject,
} from '@apollo/client';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { createUploadLink } from 'apollo-upload-client';
import { getAccessToken } from '@/tools/auth/store/authStore';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import {
    fetchRefresh,
    handleRefreshCompleted,
    handleRefreshError,
    IncomingMessageWithCookies,
    isAccessTokenValidCallback,
} from '@/tools/auth/utils';
import getConfig from 'next/config';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

const { publicRuntimeConfig } = getConfig();

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;
let URI = publicRuntimeConfig.graphqlAPIEndpoint;

// SSR mode on production
if (typeof window === 'undefined') {
    URI = publicRuntimeConfig.graphqlAPIEndpointSSR;
}

const uploadLink = createUploadLink({
    uri: URI,
    headers: { 'Apollo-Require-Preflight': 'true' },
    credentials: 'include',
});

function createApolloClient(req?: IncomingMessageWithCookies) {
    const refreshLink = new TokenRefreshLink<{ accessToken: string }>({
        accessTokenField: 'refreshToken',
        isTokenValidOrUndefined: () =>
            isAccessTokenValidCallback(req?.cookies.accessToken),
        fetchAccessToken: () => fetchRefresh(req?.cookies.refreshToken),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        handleFetch: (accessToken: string) =>
            handleRefreshCompleted(accessToken, req),
        handleError: handleRefreshError,
    });

    const httpAuthLink = new ApolloLink((operation, forward) => {
        let accessToken = getAccessToken();
        if (!accessToken && 'accessToken' in (req?.cookies ?? {})) {
            accessToken = req?.cookies.accessToken;
        }
        const cookies: Record<string, string> = {};
        if ('refreshToken' in (req?.cookies ?? {})) {
            cookies.Cookie = `refreshToken=${req?.cookies.refreshToken}`;
        }

        operation.setContext(({ headers = {} }) => {
            return {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    ...cookies,
                    ...headers,
                },
            };
        });
        return forward(operation);
    });

    const combinedLinks = from([refreshLink, httpAuthLink, uploadLink]);

    return new ApolloClient({
        ssrMode: typeof window === 'undefined',
        link: combinedLinks,
        cache: new InMemoryCache(),
    });
}

export function initializeApollo(
    initialState = null,
    req: IncomingMessageWithCookies | undefined = undefined,
) {
    const client = apolloClient ?? createApolloClient(req);

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = client.extract();

        // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
        const data = merge(existingCache, initialState, {
            // combine arrays using object equality (like in sets)
            arrayMerge: (destinationArray, sourceArray) => [
                ...sourceArray,
                ...destinationArray.filter((d) =>
                    sourceArray.every((s) => !isEqual(d, s)),
                ),
            ],
        });

        // Restore the cache with the merged data
        client.cache.restore(data);
    }
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return client;
    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = client;

    return client;
}

export function addApolloState(
    client: ApolloClient<NormalizedCacheObject>,
    pageProps: any,
) {
    if (pageProps?.props) {
        // eslint-disable-next-line no-param-reassign
        pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
    }

    return pageProps;
}

export function useApollo(pageProps: any) {
    const state = pageProps[APOLLO_STATE_PROP_NAME];
    const store = useMemo(() => initializeApollo(state), [state]);
    return store;
}
