import jwtDecode from 'jwt-decode';

type JwtType = {
    exp: number;
    sub: string;
};

const msInSec = 1000;

// eslint-disable-next-line import/prefer-default-export
export function getUserIdAndExpirationFromJwt(token: string) {
    const decodedJwt = jwtDecode<JwtType>(token);
    const expires = new Date(decodedJwt.exp * msInSec);
    return { userId: decodedJwt.sub, expires };
}
