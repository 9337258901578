import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';
import { graphql } from '@/gql';
import { BranchGqlType, ClientZoneSection, Role } from '@/gql/graphql';
import { getUserIdAndExpirationFromJwt } from '@/common/auth-helper';
import { useAccessToken } from '@/tools/auth/store/authStore';
import { useUserStore } from '@/tools/user/store/userStore';
import getRoute from '@/common/utils/routes';
import { checkIfOnLoginOrResetPasswordRouteOrError } from '@/tools/auth/utils';
import { useQuery } from '@apollo/client';
import { ContextProviderProps } from './context-provider-types';

type CurrentUserContextValue = {
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
    initials: string;
    role?: Role;
    // TODO:
    username: string;
    branches: Array<Partial<BranchGqlType>>;
    setUserId: (newValue: string) => void;
    currentBranchId: string;
    clientZoneSections: ClientZoneSection[];
};

function getFirstCharacter(string = '') {
    return string.charAt(0);
}

export const CurrentUserContext = createContext<CurrentUserContextValue | null>(
    null,
);

export const useCurrentUserContext = (): CurrentUserContextValue => {
    const courseContext = useContext(CurrentUserContext);

    if (courseContext === null) {
        throw new Error(
            "useCourseContext can't be used outside a CourseContextValue",
        );
    }

    return courseContext;
};

const USER = graphql(/* GraphQL */ `
    query currentUser($id: ID!, $branchId: String!) {
        user(id: $id) {
            id
            firstName
            lastName
            username
            email
            role
            userBranches {
                id
                name
            }
            clientZoneSections(branchId: $branchId)
        }
    }
`);

export default function CurrentUserProvider({
    children,
}: ContextProviderProps) {
    const { branchId, setBranchId } = useUserStore();
    const router = useRouter();

    const [accessToken] = useAccessToken();

    const jwtUserId =
        accessToken && getUserIdAndExpirationFromJwt(accessToken).userId;

    const [userId, setUserId] = useState<string>(jwtUserId ?? '');

    const clientLogin = getRoute('clientLogin');
    const eLearningLogin = getRoute('elearningLogin');
    const adminLogin = getRoute('adminLogin');

    const isOnLoginOrForgotPasswordRoute =
        checkIfOnLoginOrResetPasswordRouteOrError(router.pathname);

    const { data, error } = useQuery(USER, {
        variables: { id: userId, branchId },
        onCompleted: ({ user }) => {
            if (branchId === '') {
                setBranchId(user.userBranches[0].id);
            }
        },
        skip: isOnLoginOrForgotPasswordRoute,
    });

    useEffect(() => {
        if (!!error && !isOnLoginOrForgotPasswordRoute) {
            // eslint-disable-next-line no-console
            console.log('error which causes redirect to login:', error);
            // TODO:  client / admin

            const pathname = router.asPath;
            if (pathname.includes('/elearning/')) {
                router.push(eLearningLogin);
            } else if (pathname.includes('/client/')) {
                router.push(clientLogin);
            } else if (pathname.includes('/admin/')) {
                router.push(adminLogin);
            }
        }
    }, [
        clientLogin,
        eLearningLogin,
        error,
        isOnLoginOrForgotPasswordRoute,
        router,
    ]);

    const user = useMemo(() => data?.user, [data?.user]);

    const value = useMemo(() => {
        return {
            userId,
            currentBranchId: branchId,
            email: user?.email ?? '',
            firstName: user?.firstName ?? '',
            lastName: user?.lastName ?? '',

            branches: user?.userBranches ?? [],
            fullName:
                user?.firstName && user?.lastName
                    ? `${user?.firstName} ${user?.lastName}`
                    : '',
            initials: `${getFirstCharacter(user?.firstName)}${getFirstCharacter(
                user?.lastName,
            )}`,
            setUserId,
            username: user?.username ?? '',
            role: user?.role,
            clientZoneSections: user?.clientZoneSections ?? [],
        };
    }, [userId, branchId, user]);

    return (
        <CurrentUserContext.Provider value={value}>
            {children}
        </CurrentUserContext.Provider>
    );
}
