import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import cookie from 'cookie';
import {
    ACCESS_TOKEN_COOKIE_NAME,
    getAccessToken,
    REFRESH_TOKEN_COOKIE_NAME,
} from '@/tools/auth/store/authStore';
import getRoute from '@/common/utils/routes';
import { GetServerSidePropsContext } from 'next/types';
import { setBranchId } from '../user/store/userStore';

export type IncomingMessageWithCookies = GetServerSidePropsContext['req'];

const EXPIRED_BUFFER = 1000 * 10; // 10 Seconds
type DecodedToken = {
    exp: number;
};

export const fetchRefresh = (refreshToken?: string): Promise<Response> => {
    // eslint-disable-next-line no-undef
    let options: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    };
    if (refreshToken) {
        options = {
            ...options,
            headers: {
                Cookie: `refreshToken=${refreshToken}`,
            },
        };
    }
    return fetch('http://localhost:3000/api/refresh', options);
};

export const handleRefreshCompleted = (
    accessToken: string,
    req?: IncomingMessageWithCookies,
): void => {
    if (req) {
        req.cookies.accessToken = accessToken;
    }
};

export const logout = () => {
    Cookies.remove(REFRESH_TOKEN_COOKIE_NAME);
    Cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
    setBranchId('');
};

export const handleRefreshError = (): void => {
    if (typeof window !== 'undefined') {
        logout();

        // TODO:  client / admin
        if (window.location.href.includes('/elearning/')) {
            window.location.href = getRoute('elearningLogin').pathname;
        } else if (window.location.href.includes('/admin/')) {
            window.location.href = getRoute('adminLogin').pathname;
        } else {
            window.location.href = getRoute('clientLogin').pathname;
        }
    }
};

export const isAccessTokenValid = (token: string | undefined): boolean => {
    if (!token) {
        return false;
    }

    try {
        const { exp } = jwtDecode<DecodedToken>(token);
        const expiredTimestamp = exp * 1000 - EXPIRED_BUFFER;

        if (expiredTimestamp < Date.now()) {
            return false;
        }
        return true;
    } catch {
        return false;
    }
};

export const isAccessTokenValidCallback = (t: string | undefined): boolean => {
    const token = t || getAccessToken();

    if (!token) {
        return true;
    }

    return isAccessTokenValid(token);
};

export const parseAndValidateAccessToken = (
    cookieToParse: string | undefined,
): boolean => {
    if (typeof cookieToParse === 'string') {
        const { accessToken } = cookie.parse(cookieToParse);

        return isAccessTokenValid(accessToken);
    }

    return false;
};

export const checkIfOnLoginOrResetPasswordRouteOrError = (
    pathname: string,
): boolean => {
    return (
        pathname === '/error' ||
        pathname === getRoute('adminLogin').pathname ||
        pathname === getRoute('clientLogin').pathname ||
        pathname === getRoute('elearningLogin').pathname ||
        pathname === getRoute('clientResetPassword').pathname ||
        pathname === getRoute('elearningResetPassword').pathname
    );
};
