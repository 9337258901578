// import { useMemo } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type UserStore = {
    branchId: string;
    setBranchId: (branchId: string) => void;
};

function store(set: any): UserStore {
    return {
        //  state
        branchId: '',

        // methods
        setBranchId: (branchId: string | null) =>
            set({
                branchId,
            }),
    };
}

const persistedStore = persist(store, { name: 'user' });

export const useUserStore = create<UserStore>()(persistedStore);

export function getBranchId(): string {
    return useUserStore.getState().branchId;
}

export function setBranchId(branchId: string): void {
    return useUserStore.getState().setBranchId(branchId);
}
