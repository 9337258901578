import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import { SnackbarKey, useSnackbar } from 'notistack';

type Props = {
    snackbarKey: SnackbarKey;
};

const WhiteClearIcon = styled(ClearIcon)`
    color: white;
`;

export default function ToastCloseButton({ snackbarKey }: Props) {
    const { closeSnackbar } = useSnackbar();
    return (
        <Button onClick={() => closeSnackbar(snackbarKey)}>
            <WhiteClearIcon />
        </Button>
    );
}
